<template>
  <div class="storeCommission content-index">
    <div class="nav">
      <el-page-header @back="goBack" content="门店提成明细"></el-page-header>
    </div>
    <div class="fromBox">
      <el-form ref="form" :model="formData" label-width="10px" class="search">
        <el-form-item>
          <el-date-picker
            v-model="dates"
            type="daterange"
            align="right"
            unlink-panels
            size="medium"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="selectTime"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="type">
          <el-select
            v-model="formData.type"
            placeholder="请选择类型"
            size="medium"
            clearable
          >
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="storeUserId">
          <el-select
            v-model="formData.storeUserId"
            placeholder="请选择店长"
            size="medium"
            clearable
          >
            <el-option
              :label="item.userName"
              :value="item.userId"
              v-for="item in storeUserList"
              :key="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="queryInfo">
            查询
          </el-button>
          <el-button size="medium" @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="storeShopowner">
      <h3>门店店长({{ pageData.storeUserList.length }})</h3>
      <el-card class="box-card" v-loading="loading">
        <div slot="header" class="clearfix">
          <span>店长名称</span>
          <span style="float: right; padding: 3px 0;">
            提成金额
          </span>
        </div>
        <div class="cardCentent">
          <p v-if="pageData.storeUserList.length <= 0">
            暂无数据
          </p>
          <div v-else>
            <p v-for="(item, index) in pageData.storeUserList" :key="index">
              <span>{{ item.userName }}</span>
              <span>{{ item.commissionPrice }}</span>
            </p>
          </div>
        </div>
      </el-card>
    </div>
    <div class="projectCommission">
      <h3>项目提成</h3>
      <el-row :gutter="20" v-loading="loading">
        <el-col :span="6">
          <el-card class="cardBox">
            <div slot="header" class="clearfix">
              <span>总提成</span>
            </div>
            <p>{{ pageData.totalCommission }}</p>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="cardBox">
            <div slot="header" class="clearfix">
              <span>收衣提成</span>
            </div>
            <p>{{ pageData.tackCommission }}</p>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="cardBox">
            <div slot="header" class="clearfix">
              <span>办卡提成</span>
            </div>
            <p>{{ pageData.cardCommission }}</p>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="cardBox">
            <div slot="header" class="clearfix">
              <span>充值提成</span>
            </div>
            <p>{{ pageData.rechargeCommission }}</p>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%;" v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="serialNumber"
          label="流水号"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="顾客姓名"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="120"
          align="center"
          v-hidephone
        ></el-table-column>
        <el-table-column
          prop="commissionType"
          label="提成类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="actuallyPaidPrice"
          label="实付金额"
          align="center"
        ></el-table-column>
        <el-table-column prop="commissionPrice" label="提成金额" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.decimalType == 1"
              style="color: #7ec82f; font-weight: 700;"
            >
              {{ scope.row.commissionPrice }}
            </span>
            <span v-else style="color: #f92672; font-weight: 700;">
              -{{ scope.row.commissionPrice }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderTime"
          label="提成时间"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="commissionStatus"
          label="状态"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="storeName"
          label="消费门店"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refundTime"
          label="退款时间"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              :disabled="!scope.row.currentStore"
              @click="gotoOrder(scope.row)"
            >
              关联订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="formData.current"
          :page-size="formData.size"
          layout="total, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getStoreUser, commissionDel } from '@/api/commission'
export default {
  name: 'storeCommission',
  data() {
    return {
      formData: {
        size: 10,
        current: 1,
        type: '',
        storeUserId: '',
        beginTime: '',
        endTime: '',
      },
      total: 0,
      loading: false,
      dates: [
        this.$moment().startOf('month').format('YYYY-MM-DD'),
        this.$moment().format('YYYY-MM-DD'),
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, start])
            },
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 90 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            )
          }
        },
      },
      tableData: [],
      storeUserList: [],
      typeList: [
        { text: '收衣', value: 1 },
        { text: '办卡', value: 2 },
        { text: '充值', value: 3 },
      ],
      pageData: {
        storeUserList: [],
      },
    }
  },

  created() {
    this.storeUser()
    this.selectTime()
    this.pageInfo()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    selectTime() {
      this.formData.beginTime = this.dates ? this.dates[0] : ''
      this.formData.endTime = this.dates ? this.dates[1] : ''
    },
    handleCurrentChange(val) {
      this.formData.current = val
      this.pageInfo()
    },
    queryInfo() {
      this.formData.current = 1
      this.pageInfo()
    },
    async storeUser() {
      const { data: res } = await getStoreUser()
      console.log(res)
      this.storeUserList = res
    },
    async pageInfo() {
      this.loading = true
      try {
        const { data: res } = await commissionDel({
          ...this.formData,
          queryFlag: 1,
        })
        console.log(res)
        if (res.code != 0) {
          this.$message.error(res.msg)
          this.loading = false
          return
        }
        this.tableData = res.data.records
        this.pageData = res.data
        this.total = res.data.total
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    reSet() {
      this.$refs.form.resetFields()
      this.dates = null
      this.selectTime()
      this.pageInfo()
    },
    gotoOrder(data) {
      if (data.commissionType == '洗衣提成') {
        this.$router.push({
          path: '/home/order',
          query: {
            orderNumber: data.serialNumber,
          },
        })
      } else if (data.commissionType == '办卡提成') {
        //办卡提成
        this.$router.push('/home/more/applyCardList')
      } else {
        //充值 提成
        this.$router.push('/home/more/applyCardList')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.storeCommission {
  padding: 15px;
  overflow: auto;
  .fromBox {
    margin-top: 20px;
    .search {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .box-card {
    width: 480px;
    .cardCentent {
      max-height: 300px;
      overflow: auto;
      p {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
      }
    }
  }
  .cardBox {
    .clearfix {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .tableBox {
    margin-top: 30px;
    .footer {
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
